import React, { useEffect, useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import bepassLogo from '../images/bepass_logo.png'
import tokioLogo from '../assets/images/client_logo.jpg'
import fanLogo from '../assets/images/fanbase-logo.png'

const SideBar = () => {

  const activeRole = sessionStorage.getItem('role')

  const sessionName = sessionStorage.getItem('name')

  const [orgLogo, setOrgLogo] = useState(tokioLogo)

  function logMeOut() {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('gate')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('company')
    return (window.location.href = '/')
  }




  useEffect(() => {
    const companyId = sessionStorage.getItem('company')
    if (companyId === '8e0638ba-bad9-4218-ad65-d0dbb2ea6fa6') {
      setOrgLogo(fanLogo)
    }

  }, [])

  return (
    <div className="custom-navBar">
      <CDBSidebar breakpoint={991}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none logo-header" style={{ color: 'inherit' }}>
            <img src={tokioLogo} alt="BePass" />
          </a>
        </CDBSidebarHeader>


        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
          <NavLink to="/events/d760a387-cc8a-4e56-86fa-51d14f86503a" >
            <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
          </NavLink>

          </CDBSidebarMenu>
        </CDBSidebarContent>


        <CDBSidebarFooter>



          <CDBSidebarMenu>
            <NavLink onClick={logMeOut} to="/" >
              <CDBSidebarMenuItem icon="sign-out-alt">Sair</CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>


          <div className='bepass-logo' >
            <img src={bepassLogo} alt="Bepass" />
            {/* <i className="fa fa-bars fa-large"></i> */}
          </div>
        </CDBSidebarFooter>

        {/* 
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Sidebar
          </a>
        </CDBSidebarHeader> */}

      </CDBSidebar>
    </div >
  );
};


export default SideBar;